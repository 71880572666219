import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./reducers";
import rootSaga from "./saga";
import { AppState } from "./types";

export const initState: AppState = {
    user: {
        id: null,
        loading: false,
        error: null,
        stripePublicKey: "",
        role: "",
        username: "",
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        mobileNumber: "",
        skypeEmail: "",
        image: "",
        nursingHome: "",
        department: "",
        homeAddress: "",
        homeCity: "",
        homePostalCode: "",
        nursingHomeAddress: "",
        isSenior: false,
        isAdmin: false,
        readOnly: false,
        orders: [],
        group: {
            id: null,
            name: "",
            members: [],
        },
        isLoggedIn: false,
        tabletLanguage: "",
        serviceOption: "",
        autoAnswer: "",
        chosenService: "",
    },
    users: {
        list: [],
        loading: false,
        error: null,
        profile: {},
    },
    resources: {
        services: [],
        loading: false,
        deleting: false,
        deleted_at: 0,
        error: null,
    },
    services: {
        list: [
            {
                name: "",
                description: "",
                price: 0,
            },
        ],
        loading: false,
        error: null,
    },
    products: {
        products: [],
        loading: false,
        error: null,
    },
    categories: {
        category: undefined,
        loading: false,
        error: null,
    },
    alerts: {
        list: [
            {
                text: "",
                start: "",
                end: "",
            },
        ],
        deleted_at: null,
        loading: false,
        error: null,
    },
    seniorHomes: {
        list: [
            {
                reference: "",
                name: "",
                show: true,
            },
        ],
        deleted_at: null,
        loading: false,
        error: null,
    },
    blogs: {
        blogs: [
            {
                id: 1,
                url: "",
                title: "",
                content: "",
                contentPreview: "",
                coverType: "Image",
                cover: "",
                createdAt: "",
                createdBy: "admin ",
                featured: false,
                publishedAt: "",
                topics: null,
                updatedAt: ""
            }
        ],
        loading: false,
        error: null,
    },
    coupons: {
        list: [
            {
                text: "",
                data: {},
            },
        ],
        deleted_at: null,
        loading: false,
        error: null,
    },
    cart: {
        usePickupPoint: true,
        pickupPoint: {
            address1: null,
            address2: null,
            city: null,
            contact: null,
            countryCode: null,
            email: null,
            fax: null,
            id: null,
            name: null,
            openingHours: null,
            phone: null,
            serviceCode: null,
            serviceType: null,
            sms: null,
            state: null,
            zipcode: null,
        },
        inCart: [],
        shippingMethod: "",
        name: "",
        price: 0,
        shippingPrice: 0,
        address: "",
        postalCode: "",
        city: "",
        country: "",
        email: "",
        mobile: "",
        paymentMethod: "",
        stripePaymentMethod: "",
        taxPrice: 0,
        totalPrice: 0,
        coupon: null,
        couponData: null,
        nursingHome: null,
    },
    orders: {
        list: [],
        loading: false,
        error: null,
    },
    nurses: {
        list: [],
        loading: false,
        error: null,
        profile: {},
        seniorList: [],
        message: {},
    },
    itSupport: {
        list: [],
        loading: false,
        error: null,
        itSupportProfile: {},
        message: {},
    },
    videoMeet: {
        loading: false,
        error: null,
        message: {},
        participants: [],
    },
    shippingMethods: {
        shipping: [],
        loading: false,
        error: undefined,
    },
    faqs: {
        faqs: [],
        loading: false,
        error: undefined,
    },
};

export default function makeStore(initialState = initState) {
    const sagaMiddleware = createSagaMiddleware();
    let composeEnhancer = compose;

    const localState = localStorage.getItem("initState");
    if (localState) {
        const _localState: any = JSON.parse(localState);
        initialState = {
            ..._localState,
            videoMeet: initState.videoMeet,
        };
    }

    if (process.env.NODE_ENV === "development") {
        if ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
            composeEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
        }
    }

    const store = createStore(
        rootReducer(),
        // @ts-ignore
        initialState,
        composeEnhancer(applyMiddleware(sagaMiddleware))
    );

    sagaMiddleware.run(rootSaga);

    if ((module as any).hot) {
        (module as any).hot.accept("./reducers", () => {
            const nextReducer = require("./reducers").default;
            store.replaceReducer(nextReducer);
        });
    }

    return store;
}
