import {
    BlogItem,
    GET_BLOGS_FAIL,
    GET_BLOGS_REQUEST,
    GET_BLOGS_SUCCESS,
    RESET_BLOG,
} from '../types'

// For admin

export const getBlogsRequest = () => {
    return {
        type: GET_BLOGS_REQUEST,
    }
}

export const getBlogsSuccess = (blogs: BlogItem[]) => {
    return {
        type: GET_BLOGS_SUCCESS,
        payload: blogs
    }
}

export const getBlogsFail = (error: any) => {
    return {
        type: GET_BLOGS_FAIL,
        payload: {
            error
        }
    }
}

export const ResetBlog = () => {
    return {
        type: RESET_BLOG,
        payload: {}
    }
}