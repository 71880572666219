import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";

import Logo from "./Logo";
import { AppState } from "../redux/types";
import LocalStorage from "../local-storage";
import {
  logoutUser,
  ResetBlog,
  ResetCoupons,
  ResetOrders,
  ResetUsers,
} from "../redux/actions";
import { ResetShoppingcart } from "../redux/actions";

const NavBar = () => {
  const user = useSelector((state: AppState) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    LocalStorage.removeToken();
    history.push("/");
    dispatch(logoutUser());
    dispatch(ResetShoppingcart());
    dispatch(ResetUsers());
    dispatch(ResetBlog());
    dispatch(ResetCoupons());
    dispatch(ResetOrders());
  };

  return (
    <Navbar
      collapseOnSelect
      expand="xl"
      bg="light"
      variant="light"
      className="custom-nav"
    >
      <LinkContainer to="/">
        <Navbar.Brand className="logo">
          <Logo />
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        className="hamburger"
      />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <LinkContainer to="/osta">
            <Nav.Link>Osta</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/senioritabletti">
            <Nav.Link>Senioritabletti</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/senioripuhelin">
            <Nav.Link>Senioripuhelin</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/turvaranneke">
            <Nav.Link>Turvaranneke</Nav.Link>
          </LinkContainer>
          {/* <LinkContainer to="/tilaa">
            <Nav.Link>Tilaa</Nav.Link>
          </LinkContainer> */}
          <LinkContainer to="/yhteydenotto">
            <Nav.Link>Yhteydenotto</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/usein_kysyttya">
            <Nav.Link>Usein kysyttyä</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/yrityksille">
            <Nav.Link>Yrityksille</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/aboutus">
            <Nav.Link>Meistä</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/happysenior_blogi">
            <Nav.Link>Happysenior blogi</Nav.Link>
          </LinkContainer>
          {user.isLoggedIn ? (
            <NavDropdown title="Kirjautunut" id="user-firstname">
              <LinkContainer to="/user">
                <NavDropdown.Item>Tili</NavDropdown.Item>
              </LinkContainer>
              <NavDropdown.Item onClick={handleLogout}>
                Kirjaudu ulos
              </NavDropdown.Item>
            </NavDropdown>
          ) : (
            <LinkContainer to="/kirjaudu">
              <Nav.Link>Kirjaudu</Nav.Link>
            </LinkContainer>
          )}
          {user && (user.isAdmin || user.role === "it_support") && (
            <NavDropdown title="Admin" id="adminmenu">
              <LinkContainer to="/admin/users">
                <NavDropdown.Item>Asiakkaat</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/admin/services">
                <NavDropdown.Item>Palvelut</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/admin/orders">
                <NavDropdown.Item>Tilaukset</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/admin/coupons">
                <NavDropdown.Item>Coupons</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/admin/senior_homes">
                <NavDropdown.Item>Senior homes</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/admin/nurse">
                <NavDropdown.Item>Hoitaja</NavDropdown.Item>
                {/* Nurse */}
              </LinkContainer>
              {user && user.isAdmin && (
                <>
                  <LinkContainer to="/admin/alerts">
                    <NavDropdown.Item>Alerts</NavDropdown.Item>
                  </LinkContainer>
                  {/* <LinkContainer to="/admin/blogs">
                    <NavDropdown.Item>Blog</NavDropdown.Item>
                  </LinkContainer> */}
                  <LinkContainer to="/admin/it_support">
                    <NavDropdown.Item>It-tuki</NavDropdown.Item>
                    {/* IT Support */}
                  </LinkContainer>
                </>
              )}
            </NavDropdown>
          )}
          <div className="weglot" />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
