import {
    BlogsActions,
    BlogsState,
    GET_BLOGS_SUCCESS,
    RESET_BLOG
} from '../types'

export const blog = (
    state: any = {
        blogs: [],
        loading: false,
        error: null,
    },
    action: BlogsActions
): BlogsState => {
    switch (action.type) {
        case GET_BLOGS_SUCCESS:
            return {
                error: null,
                loading: false,
                blogs: action.payload
            }

        case RESET_BLOG:
            return {
                blogs: [],
                loading: false,
                error: null,
            }

        default:
            return state
    }
}

export default blog
