import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import MDEditor from "@uiw/react-md-editor";
import SubNavBar from '../components/SubNavBar'
import { AppState } from "../redux/types";
import { getBlogsRequest } from "../redux/actions";

const Article = () => {
    const { id } = useParams<{ id: string }>()

    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(() => {
        dispatch(getBlogsRequest())
        return () => {
        }
    }, [dispatch, history])

    const resources = useSelector((state: AppState) => state.blogs)
    const { blogs } = resources
    const article = blogs.find(el => el.url === id || el.id === parseInt(id))

    if (!article) return <></>

    return (
        <>
            <Helmet>
                <title>{article.title ? `Digihappy | ${article.title}` : "Digihappy | Happysenior blogi"}</title>
                <meta name='description' content={article["contentPreview"] ?? "Digihappy Happysenior blogi"} />
            </Helmet>
            <SubNavBar />
            {article ? <div>
                <div className="section-wrapper article-header-container">
                    <div>
                        <div className="article-header block">
                            <div className="article-category article-view">

                            </div>
                            <div className="article-title  article-view">
                                <div>
                                    <h1>
                                        {article.title}
                                    </h1>
                                </div>
                            </div>
                            <div className="article-byline article-view">
                                <div className="article-byline-info">

                                </div>
                            </div>
                            {article.cover && <div className="article-feature-image">
                                {
                                    article.coverType === 'Image' && <img
                                        src={article.cover}
                                        alt="crop"
                                    />
                                }
                                {
                                    article.coverType === 'Video' && <video
                                        src={article.cover}
                                        style={{
                                            background: '#000000',
                                        }}
                                        controls
                                    >
                                    </video>
                                }
                                {
                                    article.coverType === 'Youtube' && <iframe
                                        height="100%"
                                        src={`https://www.youtube.com/embed/${article.cover}`}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title={article.title}
                                        style={{
                                            margin: '0 auto',
                                        }}
                                    />
                                }
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <div
                    className="article-content"
                    data-color-mode="light"
                >
                    <MDEditor.Markdown source={article.content} />
                </div>

                <div
                    className="tags block article-topics"
                >
                    <div>
                        <div>
                            <p className="tags-container">
                                {article.topics && article.topics.split(',')?.map(item => {
                                    return <span className="button">
                                        {item}
                                    </span>
                                    }
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </div> : <div>
                404
            </div>
            }
        </>
    )
}

export default Article
