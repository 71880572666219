import {Col, Row} from 'react-bootstrap'
import {useHistory} from "react-router-dom";

const blogURI = (article: any) => article?.url || article?.id;

const ArticleFeatured = (params: any) => {
    const {featured_article} = params;
    const history = useHistory()

    return (
        <div className="blog-wrapper">
            <div className="featured-article block">
                <div className="featured-article-card-div">
                    <div
                        className="article_in_blog"
                        onClick={() => {
                            history.push(`/blog/${blogURI(featured_article)}`)
                        }}
                    >
                        <Row className="featured-article-card">
                            <Col
                                md={7} xs={12}
                                className="featured-article-card-image"
                            >
                                {
                                    featured_article.coverType === 'Image' && <img
                                        src={featured_article.cover}
                                        alt="crop"
                                    />
                                }
                                {
                                    featured_article.coverType === 'Youtube' && <img
                                        src={`https://img.youtube.com/vi/${featured_article?.cover}/maxresdefault.jpg`}
                                        alt="crop"
                                    />
                                }
                                {
                                    featured_article?.coverType === 'Video' && <video
                                        src={featured_article?.cover}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }}
                                    >
                                    </video>
                                }
                            </Col>
                            <Col md={5} xs={12} className="featured-article-card-body">
                                <p className="article-category">
                                    {featured_article.topics && featured_article.topics.split(',')?.[0]}
                                </p>
                                <h3>{featured_article?.title}</h3>
                                <p className="featured-article-card-description">
                                    {featured_article?.contentPreview}
                                </p>
                                <p className="featured-article-card-date">
                                    {(new Date(featured_article?.createdAt)).toLocaleDateString('fi-FI', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit'
                                    }).replace(/\./g, '-')}
                                </p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ArticleFeatured
