import {Col, Row} from 'react-bootstrap'
import {useHistory} from "react-router-dom";

const blogURI = (article: any) => article?.url || article?.id;

const ArticleItem = (params: any) => {
    const {item} = params;
    const history = useHistory()

    return (
        <div
            className="article_in_blog"
            onClick={() => {
                history.push(`/blog/${blogURI(item)}`)
            }}
        >
            <Row className="article-card column">
                <Col
                    xs={12}
                    className="article-card-image"
                >
                    {
                        item.coverType === 'Image' && <img
                            src={item.cover}
                            alt="crop"
                        />
                    }
                    {
                        item.coverType === 'Youtube' && <img
                            src={`https://img.youtube.com/vi/${item.cover}/maxresdefault.jpg`}
                            alt="crop"
                        />
                    }
                    {
                        item.coverType === 'Video' && <video
                            src={item.cover}
                            style={{
                                objectFit: 'cover'
                            }}
                        >
                        </video>
                    }
                </Col>
                <Col xs={12} className="article-card-body">
                    <p className="article-category">
                        {item.topics && item.topics.split(',')?.[0]}
                    </p>
                    <h3
                        title={item.title}
                    >
                        {item.title}
                    </h3>
                    <p className="featured-article-card-description">
                        {item.contentPreview}
                    </p>
                    <p className="featured-article-card-date">
                        {(new Date(item.createdAt)).toLocaleDateString('fi-FI', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit'
                        }).replace(/\./g, '-')}
                    </p>
                </Col>
            </Row>
        </div>
    )
}

export default ArticleItem
