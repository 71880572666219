import { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Table } from 'react-bootstrap'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { getBlogsRequest } from '../redux/actions'
import { AppState } from '../redux/types'

const BlogsList = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const user = useSelector((state: AppState) => state.user)

    useEffect(() => {
        if (user && user.isAdmin) {
            dispatch(getBlogsRequest())
        } else {
            history.push('/kirjaudu')
        }
        return () => {
        }
    }, [dispatch, history, user])

    const resources = useSelector((state: AppState) => state.blogs)
    const {blogs, loading, error} = resources

    return (
        <>
            <h1 className='my-3 ml-3' style={{color: '#4e5180', fontFamily: 'Poppins, sanf-serif'}}>Blog pages</h1>
            {loading ? (
                <Loader/>
            ) : error ? (
                <Message variant='danger'>{error}</Message>
            ) : (
                <Table striped bordered hover responsive>
                    <thead className='user-title'>
                    <tr className='user'>
                        <th className='text-center'>ID</th>
                        <th className='text-center'>Author</th>
                        <th className='text-center'>Title</th>
                        <th className='text-center'>Cover</th>
                        <th className='text-center'>Topics</th>
                        <th className='text-center'>Content preview</th>
                        <th className='text-center'>Featured</th>
                    </tr>
                    </thead>
                    <tbody>
                    {blogs && blogs.map(blog => (
                        <tr key={blog.id}>
                            <td>{blog.id}</td>
                            <td>{blog.createdBy}</td>
                            <td>
                                <div>
                                    <div>
                                        {blog.title}
                                    </div>
                                    <div>
                                        <a
                                            href={blog.url ?
                                                `${window.location.origin}/blog/${blog.url}` :
                                                `${window.location.origin}/blog/${blog.id}`
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                            style={{
                                                padding: '8px 0',
                                                color: '#666555',
                                                fontSize: '0.8rem',
                                            }}
                                        >
                                            {blog.url ?
                                                `${window.location.origin}/blog/${blog.url}` :
                                                `${window.location.origin}/blog/${blog.id}`
                                            }
                                        </a>
                                    </div>
                                </div>
                            </td>
                            <td>
                                {blog.coverType === 'Image' && <img
                                    style={{
                                        maxHeight: 48,
                                    }}
                                    src={blog.cover}
                                    alt='page cover'
                                />
                                }
                                {blog.coverType === 'Youtube' && <img
                                    style={{
                                        maxHeight: 48,
                                    }}
                                    src={`https://img.youtube.com/vi/${blog.cover}/maxresdefault.jpg`}
                                    alt='page cover'
                                />
                                }
                                {blog.coverType === 'Video' && <video
                                    style={{
                                        maxHeight: 48,
                                    }}
                                    src={blog.cover}
                                    title='page cover'
                                />
                                }
                            </td>
                            <td>{!!blog.topics ? blog.topics : ''}</td>
                            <td>{blog.contentPreview}</td>
                            <td>
                                {blog.featured ? (
                                    <i className='fas fa-check' style={{color: '#4e5180'}}/>
                                ) : (
                                    <i className='fas fa-times' style={{color: 'red'}}/>
                                )}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            )}
        </>
    )
}

export default BlogsList
