import { GET_FAQS_SUCCESS, GET_FAQS_FAIL, GET_FAQS_REQUEST, FaqModel } from "../types";

export const getFaqsSuccess = (faqs: FaqModel[]) => {
    return {
        type: GET_FAQS_SUCCESS,
        payload: faqs,
    };
};
export const getFaqsFail = (error: any) => {
    return {
        type: GET_FAQS_FAIL,
        payload: {
            error,
        },
    };
};
export const getFaqsRequest = () => {
    return {
        type: GET_FAQS_REQUEST,
    };
};
