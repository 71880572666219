import { put, select, takeLatest } from 'redux-saga/effects'

import {
    getBlogsFail,
    getBlogsSuccess,
} from '../actions'
import { BlogItem, CMSBlogType } from '../types'
import cmsApi from "../../utils/api-helper/cmsApi";

// for admin
function* getBlogsSaga() {
    try {
        //@ts-ignore
        const res = yield cmsApi.get("/blogs?populate[Cover][populate][Image][fields][0]=url&populate[Cover][populate][Video][fields][0]=url&populate[createdBy][populate]");
        const blogs = (res.data.data as CMSBlogType[]).map((blog) => ({
            id: blog.id,
            title: blog.attributes.Title,
            url: blog.attributes.SlugURL,
            topics: blog.attributes.Topics,
            cover: blog.attributes.Cover[0]?.__component === "boxes.image" ? blog.attributes.Cover[0].Image.data.attributes.url : blog.attributes.Cover[0]?.__component === "boxes.video" ? blog.attributes.Cover[0].Video.data.attributes.url : blog.attributes.Cover[0]?.__component === "boxes.youtube" ? blog.attributes.Cover[0].VideoId : "",
            coverType: blog.attributes.Cover[0]?.__component === "boxes.image" ? 'Image' : blog.attributes.Cover[0]?.__component === "boxes.video" ? 'Video' : 'Youtube',
            content: blog.attributes.Content,
            contentPreview: blog.attributes.ContentPreview,
            featured: blog.attributes.Featured,
            createdBy: blog.attributes.createdBy ? `${blog.attributes.createdBy.data.attributes?.firstname ?? ''} ${blog.attributes.createdBy.data.attributes?.lastname ?? ''}` : '',
            createdAt: blog.attributes.createdAt,
            updatedAt: blog.attributes.updatedAt,
            publishedAt: blog.attributes.publishedAt,
        } as BlogItem));
        yield put(getBlogsSuccess(blogs));
    } catch (error) {
        yield put(getBlogsFail(error));
    }
}

function* saveState() {
    //@ts-ignore
    const state = yield select()
    yield localStorage.setItem('initState', JSON.stringify(state))
}

const sagaWatcher = [
    takeLatest('GET_BLOGS_REQUEST', getBlogsSaga),
    takeLatest('*', saveState)
]

export default sagaWatcher
