import { FaqsState, FaqsActions, GET_FAQS_SUCCESS } from "../types";

export const faqs = (
    state: FaqsState = {
        faqs: [],
        loading: false,
        error: null,
    },
    action: FaqsActions
): FaqsState => {
    switch (action.type) {
        case GET_FAQS_SUCCESS:
            return {
                error: null,
                loading: false,
                faqs: action.payload,
            };
        default:
            return state;
    }
};

export default faqs;
